<template>
  <v-container
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col cols="12">
        <v-btn
          color="primary"
          fab
          dark
          to="/pages/contratos/create"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-card>
          <v-card-title>
            Contratos
            <v-spacer />
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="contratos"
            :options.sync="options"
            :server-items-length="total"
            :footer-props="footer_props"
            class="elevation-1"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                >
                  <td>
                    {{ item.tipoContratoId }}
                  </td>
                  <td>
                    {{ item.tiposContrato.nome }}
                  </td>
                  <td>
                    {{ item.versao }}
                  </td>
                  <td>
                    {{ timestamp(item.createdAt) }}
                  </td>
                  <td>
                    <router-link :to="`/pages/contratos/${item.contratoId}`">
                      <v-icon
                        class="mr-2"
                        color="info"
                      >
                        mdi-pencil
                      </v-icon>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Você tem certeza?
        </v-card-title>

        <v-card-text>
          O contrato será deletado. Deseja prosseguir?
        </v-card-text>

        <v-card-actions>
          <div class="flex-grow-1" />

          <v-btn
            outlined
            class="ml-4"
            large
            color="error"
            dark
            @click="dialog = false"
          >
            Espere, vou verificar
          </v-btn>

          <v-btn
            outlined
            class="ml-4"
            large
            color="success"
            dark
            @click="confirmDelete()"
          >
            Sim, prossiga
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import moment from 'moment'
  export default {
    data: () => ({
      itemselected: {},
      contratos: [],
      headers: [
        { text: 'ID', sortable: false },
        { text: 'Nome', sortable: false },
        { text: 'Versão', sortable: false },
        { text: 'Criado em', sortable: false },
        { text: 'Ação', sortable: false },
      ],
      footer_props: {
        'items-per-page-options': [5, 10, 15, 20],
      },
      dialog: false,
      total: 0,
      options: {
        sortBy: [],
        sortDesc: [],
      },
      filter: {
        PerPage: 25,
        Page: 1,
        SortAscending: true,
        Term: '',
      },
    }),
    watch: {
      options: {
        handler () {
          this.load()
        },
        deep: true,
      },
    },
    methods: {
      load: function () {
        this.filter.PerPage = this.options.itemsPerPage
        this.filter.Page = this.options.page
        const query = JSON.stringify(this.filter)
        this.$http.get('/contratos', { params: { query } })
          .then(resp => {
            this.contratos = resp.data.rows
            this.total = resp.data.total
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      edit (item) {
        this.$router.push(`/contratos/${item.id}`)
      },
      timestamp (datestring) {
        return moment(datestring).format('DD/MM/YYYY HH:mm')
      },
    },
  }
</script>

<style>

</style>
